<template>
  <div v-if="isEditable" class="p-grid">
    <div class="p-col-12">
      <h3 class="subhead">{{ _t('label_Colour') }}</h3>
    </div>
    <div v-for="(color, i) in iconColors" :key="color" class="p-col-1 p-text-center">
      <label :for="`color${i}`">
        <div :style="`background-color: ${color};`" class="color_sample p-mb-2"/>
      </label>
      <RadioButton :id="`color${i}`" v-model="getApp.icon.color" :disabled="!isEditable" :value="color"
                   name="color"/>
    </div>
    <div class="p-col-12">
      <h3 class="subhead">{{ _t('label_Icon') }}</h3>
    </div>
    <Card v-for="(section, s) in icons" :key="section.label" class="p-col-3">
      <template #title>
        <p>{{ section.label }}</p>
      </template>
      <template #content>
        <div class="p-grid">
          <div v-for="(icon, i) in section.icons" :key="icon" class="p-col-3 p-text-center">
            <label :for="`icon_${s}_${i}`">
              <i :class="`icon p-mb-2 ${icon}`"/>
            </label><br>
            <RadioButton :id="`icon_${s}_${i}`" v-model="getApp.icon.name" :disabled="!isEditable" :value="icon"
                         name="icon"/>
          </div>
        </div>
      </template>
    </Card>
  </div>
  <div v-else>
    <i :class="`icon_big p-mb-2 ${getApp.icon.name}`"
       :style="`color: #fff; background-color: ${getApp.icon.color};`"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'icon-tab',
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    iconColors: [
      '#3996f3',
      '#314c5d',
      '#fad165',
      '#85b44a',
      '#8c5cfd',
      '#46c1d3',
      '#e23e57',
      '#989898',
      '#1b499e',
      '#f5856d'
    ],
    icons: [],
  }),
  mounted() {
    this.icons = [
      {
        label: this._t('menu.general'),
        icons: [
          'mdi mdi-alert-outline',
          'mdi mdi-bell-ring-outline',
          'mdi mdi-alarm',
          'mdi mdi-alert-rhombus-outline',
          'mdi mdi-bell-alert-outline',
          'mdi mdi-car-brake-alert',
          'mdi mdi-alert-circle-outline',
          'mdi mdi-alert-octagon-outline',
          'mdi mdi-alert-decagram-outline',
          'mdi mdi-alert-box-outline',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-alarm-multiple',
          'mdi mdi-bell-circle-outline',
        ]
      },
      {
        label: this._t('label_Alarms'),
        icons: [
          'mdi mdi-exit-run',
          'mdi mdi-exit-to-app',
          'mdi mdi-fire',
          'mdi mdi-fire-extinguisher',
          'mdi mdi-air-horn',
          'mdi mdi-fire-hydrant',
          'mdi mdi-water-alert-outline',
          'mdi mdi-water-remove-outline',
          'mdi mdi-pipe-leak',
          'mdi mdi-ambulance',
          'mdi mdi-medical-bag',
          'mdi mdi-hospital-box-outline',
          'mdi mdi-zodiac-aquarius',
          'mdi mdi-wrench-outline',
          'mdi mdi-server-off',
          'mdi mdi-lan-disconnect',
          'mdi mdi-alarm-light-outline',
        ]
      },
      {
        label: this._t('label_Danger'),
        icons: [
          'mdi mdi-alarm-light-outline',
          'mdi mdi-hazard-lights',
          'mdi mdi-triforce',
          'mdi mdi-molecule-co2',
          'mdi mdi-flash-alert-outline',
          'mdi mdi-bottle-tonic-skull-outline',
          'mdi mdi-skull-crossbones-outline',
          'mdi mdi-skull',
          'mdi mdi-virus-outline',
          'mdi mdi-bacteria-outline',


        ]
      },
      {
        label: this._t('label_Security'),
        icons: [
          'mdi mdi-shield-alert-outline',
          'mdi mdi-shield-key-outline',
          'mdi mdi-account-alert-outline',
          'mdi mdi-security-network',
          'mdi mdi-security',
          'mdi mdi-lock-open-alert',
          'mdi mdi-lock-open-outline',
          'mdi mdi-key-outline',
          'mdi mdi-key-remove',
          'mdi mdi-home-alert',
          'mdi mdi-home-lock-open',
          'mdi mdi-window-shutter-alert',
          'mdi mdi-volume-off',
        ]
      },
      {
        label: this._t('label_Maintenance'),
        icons: [
          'mdi mdi-broom',
          'mdi mdi-rake',
          'mdi mdi-hammer-wrench',
          'mdi mdi-wrench-outline',
        ]
      },
      {
        label: this._t('label_IT'),
        icons: [
          'mdi mdi-help-circle-outline',
          'mdi mdi-lan-disconnect',
          'mdi mdi-server-off',
          'mdi mdi-desktop-classic',
          'mdi mdi-content-save-alert-outline',
          'mdi mdi-disc-alert',
          'mdi mdi-timeline-alert-outline',
          'mdi mdi-folder-key-outline',
          'mdi mdi-folder-alert',
          'mdi mdi-table-alert',
          'mdi mdi-wifi-strength-alert-outline',
          'mdi mdi-restart-alert',

        ]
      },
      {
        label: this._t('label_Info_Calendar'),
        icons: [
          'mdi mdi-information-outline',
          'mdi mdi-information-variant',
          'mdi mdi-clock-alert-outline',
          'mdi mdi-calendar-alert',
          'mdi mdi-exclamation',
          'mdi mdi-exclamation-thick',
          'mdi mdi-clipboard-alert-outline',
          'mdi mdi-sticker-alert-outline',
          'mdi mdi-coffee-outline',
        ]
      },
      {
        label: this._t('label_Traffic'),
        icons: [
          'mdi mdi-bus-alert',
          'mdi mdi-subway-alert-variant',
          'mdi mdi-traffic-light',
        ]
      },
      {
        label: this._t('label_Technical'),
        icons: [
          'mdi mdi-coolant-temperature',
          'mdi mdi-radioactive',
          'mdi mdi-printer-3d-nozzle-alert-outline',
          'mdi mdi-tray-alert',
          'mdi mdi-beaker-alert-outline',
          'mdi mdi-water-percent-alert',
          'mdi mdi-thermometer-alert',
          'mdi mdi-thermometer-lines',
          'mdi mdi-oil-level',
          'mdi mdi-dishwasher-alert',
          'mdi mdi-battery-alert-variant-outline',
          'mdi mdi-vibrate',
          'mdi mdi-watch-vibrate',
          'mdi mdi-fuse-alert',
          'mdi mdi-engine-outline',
          'mdi mdi-fridge-alert-outline',
          'mdi mdi-state-machine',
          'mdi mdi-gas-cylinder',
          'mdi mdi-diving-scuba-tank',
          'mdi mdi-fan-alert',
          'mdi mdi-lightbulb-on-outline',
          'mdi mdi-power-plug-off-outline',
          'mdi mdi-car-tire-alert',
          'mdi mdi-lightning-bolt-outline',
          'mdi mdi-transmission-tower',
          'mdi mdi-scale-balance',
        ]
      },
      {
        label: this._t('label_Weather'),
        icons: [
          'mdi mdi-snowflake-alert',
          'mdi mdi-snowflake-melt',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-weather-lightning',
          'mdi mdi-weather-pouring',
        ]
      },
      {
        label: this._t('label_media'),
        icons: [
          'mdi mdi-book-open-variant',
          'mdi mdi-cellphone-basic',
          'mdi mdi-cellphone',
          'mdi mdi-monitor',
          'mdi mdi-alarm-light-outline',
          'mdi mdi-phone-in-talk',
          'mdi mdi-email',
          'mdi mdi-card-text-outline',
          'mdi mdi-cellphone-text',
          'mdi mdi-message-text',
          'mdi mdi-cellphone-wireless',
          'mdi mdi-message-video',
          'mdi mdi-application-import',
          'mdi mdi-database-import',
          'mdi mdi-phone-voip',
          'mdi mdi-application-export',
          'mdi mdi-printer',
          'mdi mdi-database-export',
          'mdi mdi-motion-sensor',
          'mdi mdi-switch',
          'mdi mdi-serial-port',
          'mdi mdi-lighthouse',
          'mdi mdi-lighthouse',
          'mdi mdi-led-on',
          'mdi mdi-cup-water',
          'mdi mdi-gesture-tap-hold',
        ]
      }
    ]
  },
  methods: {},
  computed: {
    ...mapGetters({
      'getApp': 'apps/getApp',
    }),
  },
}
</script>

<style scoped>
.hidden {
  display: none;
}

.color_sample {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 0 auto;
}

.icon_big {
  font-size: 48px;
  padding: 4px;
  border-radius: 4px;
}

.icon_medium {
  font-size: 24px;
  padding: 4px;
  border-radius: 4px;
}

.icon {
  font-size: 24px;
}

/*:deep(.p-tabview-panels .p-tabview-nav-container) {*/
/*  display: none;*/
/*}*/

/*:deep(.p-tabview-panels .p-tabview-panels) {*/
/*  padding: 0;*/
/*}*/
</style>
