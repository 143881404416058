<template>
  <div v-if="getApp.instructionFiles && getApp.instructionFiles.length" class="p-grid p-nogutter p-mb-4">
    <div v-for="(instruction, index) in getApp.instructionFiles"
         :key="index"
         class="p-col-12"
    >
      <Button :label="instruction.split('/').pop()"
              class="p-button-link p-button-text p-button-success"
              @click="downloadInstruction(instruction)"
      />
      <Button v-if="isEditable"
              class="p-button-rounded p-button-danger p-button-text"
              icon="pi pi-times"
              @click="deleteIntruction(instruction)"
      />
    </div>
  </div>
  <p v-else><b>{{ _t('label_intruction_file_missed') }}</b></p>
  <div v-if="isEditable" class="p-grid">
    <div class="p-col-3 stripedBg">
      <p>{{ _t('select_instruction_file') }}</p>
    </div>
    <div class="p-col-5 stripedBg">
      <Dropdown v-if="isEditable"
                v-model="getApp.instructionFile"
                :options="instructions"
                class="p-m-0"
                optionLabel="name"
                optionValue="path"
                style="width: 100%;"
      >
        <template #option="slotProps">
          <div class="p-dropdown-option">
                        <span v-if="slotProps.option.org.id != 1">
                          {{ slotProps.option.org.fullOrgPath.replace('[Total system]-', '') }} -
                        </span> {{ slotProps.option.name }}
          </div>
        </template>
      </Dropdown>
      <!--                  <Button v-else-if="getApp.instructionFile"-->
      <!--                          :label="getApp.instructionFile"-->
      <!--                          class="p-button-link p-button-text"-->
      <!--                          @click="downloadInstruction(getApp.instructionFile)"-->
      <!--                  />-->
    </div>
    <div class="p-col-4 stripedBg">
      <Button v-if="isEditable"
              :label="_t('label_assign')"
              class="p-button-success"
              @click="addInstruction"
      />
      <span style="float: right"><UploadFile @uploaded="loadInstructions"/></span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {downloadInstruction} from '@/api/instruction';
import {getInstructionFiles} from '@/api/attachments';
import UploadFile from '@/components/UploadFile'

export default {
  name: 'attachments-tab',
  components: {UploadFile},
  props: {
    isEditable: Boolean,
  },
  data: () => ({
    instructions: [],
  }),
  mounted() {
    this.loadInstructions()
  },
  methods: {
    downloadInstruction(instructionFile) {
      downloadInstruction(instructionFile)
    },
    deleteIntruction(instruction) {
      this.getApp.instructionFiles
          .splice(this.getApp.instructionFiles.findIndex(i => i === instruction), 1)
    },
    addInstruction() {
      if (!this.getApp.instructionFiles) {
        this.getApp.instructionFiles = [];
      }
      if (this.getApp.instructionFiles.indexOf(this.getApp.instructionFile) === -1) {
        this.getApp.instructionFiles.push(this.getApp.instructionFile);
      }
      this.getApp.instructionFile = null;
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              }
            }
          })
    },
    getInstructionsRecursive(instructions, collection = [], org = {}) {
      for (const instruction of instructions) {
        if (instruction.files && instruction.files.length) {
          collection.concat(this.getInstructionsRecursive(instruction.files, collection, instruction.org))
        } else {
          collection.push({...instruction, org})
        }
      }
      return collection
    },
  },
  computed: {
    ...mapGetters({
      'getApp': 'apps/getApp',
    })
  }
}
</script>
