<template>
  <div class="p-grid">
    <Card class="style p-col-2">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div class="p-inputgroup">
            <div>
              <h3 class="p-mt-0"><strong>{{ _t('menu.alarm_apps') }}</strong></h3>
              <MultiSelectableBreadcrumb ref="breadcrumbData" :singleSelection="true" @change="setOrgId($event)" tree-params="category_filter=GEOGRAPHICAL_SCOPE"/>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <Listbox
            v-model="selApp"
            :options="availableAppsList"
            optionLabel="label"
            optionValue="value"
            style="margin: -1.5rem; font-size: 12px; padding: 0px 5px 0px 0px;!important; height:85vh;"
            @change="selectAlarmApp"
        />
      </template>
    </Card>
    <transition v-if="getApp" name="slide-fade">
      <Card class="p-col" style="overflow-x: scroll;">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div>
              <h3 class="p-m-0">
                {{ _t('label_App_alarm') }} <strong>{{ getApp.name }}</strong>
              </h3>
            </div>
            <div>
              <Button v-if="getIsEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="cancelAlarmApp"/>
              <Button v-if="getIsEditable" v-tooltip.bottom="_t('Save')"
                      class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check"
                      @click="saveAlarmApp()"/>
              <Button v-if="!getIsEditable" v-tooltip.bottom="_t('Edit')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil"
                      @click="setIsEditable(!getIsEditable)"/>
              <Button v-if="!getIsEditable" v-tooltip.bottom="_t('Delete')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash"
                      @click="deleteAlarmApp"/>
            </div>
          </div>
        </template>
        <template #content>
          <div class="p-d-flex">
            <TabView style="width: 100%">
              <TabPanel :header="_t('menu.general')">
                <BasicsTab :isEditable="getIsEditable"/>
              </TabPanel>
              <TabPanel :header="_t('PARTICIPANTS')">
                <ParticipantsTab ref="participants" :isEditable="getIsEditable"/>
              </TabPanel>
              <TabPanel :header="_t('label_instruction_files')">
                <AttachmentsTab :isEditable="getIsEditable"/>
              </TabPanel>
              <TabPanel :header="_t('label_advanced')">
                <AdvancedTab :isEditable="getIsEditable"/>
              </TabPanel>
              <TabPanel :header="_t('label_Icon')">
                <IconTab :isEditable="getIsEditable"/>
              </TabPanel>
            </TabView>
          </div>
        </template>
      </Card>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb';
import BasicsTab from './tabs/basics-tab';
import ParticipantsTab from './tabs/participants-tab';
import AdvancedTab from './tabs/advanced-tab';
import AttachmentsTab from './tabs/attachments-tab';
import IconTab from './tabs/icon-tab';

export default {
  name: 'app-index',
  components: {
    MultiSelectableBreadcrumb,
    BasicsTab,
    ParticipantsTab,
    AdvancedTab,
    AttachmentsTab,
    IconTab
  },
  data: () => ({
    selApp: null,
    availableAppsList: []
  }),
  mounted() {
    this.getList()
  },
  watch: {
    getOrgId() {
      this.getList()
      this.selectAlarmApp()
    }
  },
  methods: {
    ...mapActions({
      'callApp': 'apps/callApp',
      'callRemoveApp': 'apps/callRemoveApp',
      'callUpdateApp': 'apps/callUpdateApp',
      'callCreateApp': 'apps/callCreateApp',
    }),
    ...mapMutations({
      'setApp': 'apps/setApp',
      'setOrgId': 'apps/setOrgId',
      'setIsEditable': 'apps/setIsEditable',
    }),
    selectAlarmApp() {
      this.callApp(this.selApp)
          .then(() => this.$refs.participants.updatePlan())
    },
    deleteAlarmApp() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.callRemoveApp()
          this.selApp = null
        }
      });
    },
    cancelAlarmApp() {
      this.callApp(this.selectedApp)
      this.$refs.participants.selectedAlarmPlan = {
        id: null
      }
    },
    saveAlarmApp() {
      if (this.getApp && this.getApp.id) {
        this.callUpdateApp()
      } else {
        this.callCreateApp()
      }
    },
    getList() {
      this.availableAppsList = this.getAvailableApps.map((el) => ({
        label: this._t(`label_APP_${el.label}`),
        value: el.value
      }))
    }
  },
  computed: {
    ...mapGetters({
      'getAvailableApps': 'apps/getAvailableApps',
      'getApp': 'apps/getApp',
      'getOrgId': 'apps/getOrgId',
      'getIsEditable': 'apps/getIsEditable',
    }),
    selectedApp: {
      get: function () {
        return this.selApp
      },
      set: function (val) {
        this.setApp(val)
        this.selApp = val
      }
    }
  }
}
</script>
